/* global */

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    letter-spacing: 0;
    overflow: hidden;
}

* {
    font-family: 'Open Sans', 'Helvetica', 'Roboto', 'Arial', 'PingFang TC', 'PingFang SC',
        'Microsoft JhengHei', 'Microsoft YaHei', 'Hiragino Sans GB', sans-serif;
}
a {
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}

/* element-theme-adjust */

.el-button {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #818181;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    transition: border-color 300ms, color 300ms;
    cursor: pointer;
    padding: 0.5rem 2.0625rem;
    text-align: center;
    line-height: 21px;
    font-size: 0.8125rem;
    border-radius: 4px;
    outline: none;
}
.el-button:focus {
    outline: none;
    color: #818181;
    border-color: #cccccc;
}
.el-button:not(.el-button--primary):hover {
    border-color: #c21e24;
    color: #c21e24;
    outline: none;
}
.el-button:not(.el-button--primary):active {
    border-color: #c21e24;
    color: #c21e24;
    transform: translateY(2px);
    box-shadow: none;
}
.el-button--primary {
    background-color: #d0021b;
    border: 1px solid #d0021b;
    border-radius: 4px;
    color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    transition: border-color 300ms, color 300ms;
    cursor: pointer;
    padding: 0.5rem 2.0625rem;
    text-align: center;
    line-height: 21px;
    font-size: 0.8125rem;
    border-radius: 4px;
    outline: none;
}
.el-button--primary:focus,
.el-button--primary:hover {
    border-color: #c21e24;
    background-color: #c21e24;
    outline: none;
}
.el-button--primary:active {
    border-color: #c21e24;
    background-color: #c21e24;
    transform: translateY(2px);
    box-shadow: none;
}
.el-select-dropdown {
    border: 1px solid rgb(130, 146, 166);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 4px;
}
.el-select-dropdown__list {
    padding-top: 0px;
    padding-bottom: 0px;
}
.el-select-dropdown__list > li:first-of-type {
    border-radius: 4px 4px 0px 0px;
}
.el-select-dropdown__list > li:last-of-type {
    border-radius: 0px 0px 4px 4px;
}
.el-select-dropdown__wrap {
    max-height: 252px;
}
.el-select-dropdown__item {
    color: #333;
}
.el-select-dropdown__item:hover {
    background-color: #ececec;
}
.el-select-dropdown__item.selected {
    background-color: transparent;
    color: #333;
}
.el-select-dropdown__item.selected.hover {
    background-color: #e4e8f1;
}
.el-form-item__label {
    font-size: 16px;
}
.el-input {
    font-size: 16px;
}
.el-notification__closeBtn {
    display: none;
}
.el-message-box {
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3);
}
.el-message-box__title {
    height: auto;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}
.el-message-box__header {
    padding: 12px;
    border-bottom: 1px solid #ececec;
}
.el-message-box__close {
    display: none;
}
.el-message-box__message {
    text-align: center;
}
.el-message-box__message p {
    max-width: 250px;
    margin: auto;
}
.el-message-box__btns {
    text-align: center;
}
.el-notification .el-icon-circle-check {
    color: #2ab3a6;
}
.el-dialog {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.3);
}

.recharts-bar .recharts-bar-rectangle .recharts-rectangle:hover {
    fill: #66adff;
}

.download_role_hint .el-message-box__title {
    text-align: left;
    font-weight: 500;
}

.download_role_hint .el-message-box__content {
    padding: 10px 12px 40px;
}

.download_role_hint .el-message-box__message {
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
}

.download_role_hint .el-message-box__btns {
    text-align: right;
}

.download_role_hint .el-button--primary {
    padding: 8px 15px;
}
